@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

body {
  font-family: 'Nunito', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  text-shadow: 0 0 10px rgba(255, 105, 180, 0.7);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  color: #bd2323;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

section {
  padding: 4rem 0;
}

h2 {
  color: #ff0000;
}

p {
  color: #000000;
}

#home {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

header {
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(10px);
  transition: background 0.3s ease-in-out;
}

header a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s;
}

header a:hover {
  color: #ff0000;
}

footer {
  background-color: #111;
  color: #fff;
  padding: 2rem 0;
  text-align: center;
}

footer a {
  color: #ff0000;
  text-decoration: none;
}

footer a:hover {
  color: #ffffff;
}

section {
  padding: 4rem 0;
  background-color: transparent; /* or whatever color you want */
}

h2 {
  color: #ff0000;
}

p {
  color: #ffffff;
}